<!--电商销售出入库-->
<template>
    <div class="Stock">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="120px">
                <div class="boxwrap">
                    <div class="boxitem">
                        <el-form-item label="订单编号">
                            <el-input placeholder="订单编号" v-model="form.orderNumber" style="width: 180px" />
                        </el-form-item>
                    </div>
                    <div class="boxitemflex2">
                        <el-form-item label="机构名称">
                            <efn-group-dept @change="handleChangeDepts"></efn-group-dept>
                        </el-form-item>
                    </div>
                </div>
                <div class="boxwrap">
                    <div class="boxitem">
                        <el-form-item label="收件人">
                            <el-input placeholder="收件人" v-model="form.receiverSearch" style="width: 180px" />
                        </el-form-item>
                    </div>
                    <div class="boxitemflex2">
                        <el-form-item label="创建日期-开始">
                            <el-date-picker
                                type="date"
                                v-model="form.stockStartTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                        <el-form-item label="创建日期-结束">
                            <el-date-picker
                                type="date"
                                v-model="form.stockEndTime"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                style="width: 180px"
                            />
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.order.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.mall.manage.order.export')"
                >导出
            </el-button>
            <el-button type="primary" @click="clearSearch" size="small"> 清空 </el-button>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" base-url="/promotion" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="table"
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="机构名称" prop="deptName" width="170" />
                <el-table-column label="订单编号" prop="orderNumber" />
                <el-table-column label="出库件数" width="160" prop="deliverySumCount" />
                <el-table-column label="出库金额" width="160" prop="deliveryActualTotalYuan" />
                <el-table-column label="收件人" width="160" prop="receiverName">
                    <template slot-scope="scope">
                        <div v-if="scope.row.receiverName">{{ scope.row.receiverName }}</div>
                        <div v-if="scope.row.receiverMobile">{{ scope.row.receiverMobile }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="出库时间" width="160" prop="updateTime" />
                <el-table-column label="接单员工" width="160" prop="receiveOrderEmployeeName" />
                <el-table-column label="配送员" width="160" prop="deliveryEmployeeName" />
                <el-table-column
                    label="操作"
                    min-width="30"
                    header-align="center"
                    v-if="showColumn('operate')"
                    key="operate"
                    prop="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.mall.manage.order.open')"
                            >查看</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfReviewBiz from 'components/EfReviewBiz';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import EfnGroupDept from 'components/newStyle/EfnGroupDept';
export default {
    name: 'Plan',
    mixins: [CheckTableShowColumn],
    components: { EfReviewBiz, CheckTableShowColumnDialog, EfnGroupDept },
    data() {
        return {
            form: {
                orderNumber: '', //订单号
                search: '', //商品名称
                deptGroupCode: '', //机构组code
                deptCode: '', //机构code
                receiverSearch: '', //收货人姓名和电话
                stockStartTime: Util.formatDateOfLastMonth(),
                stockEndTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                // page: '/fromMultishop/onlineShoppingDeliveryOrder/page',
                page: '/fromMultishop/onlineShoppingDeliveryOrder/pageList',
                review: '/promotion/review/',
            },
            meta: {
                depts: [],
                reviewEnums: Util.reviewStatusEnum(true),
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.meta.depts = rst;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleChangeDepts(depts) {
            this.form.deptGroupCode = depts.groupCode;
            this.form.deptCode = depts.deptCode;
            this.form.deptName = depts.deptName;
            if (!this.meta.inited) {
                this.meta.inited = true;
                //初始化,查询一次
                this.handleQuery();
            }
        },
        handleOn(row) {
            const disable = row.reviewStatus == 2 && !row.enableFlag;
            if (!disable) {
                this.$message.error('该促销不能被启用,请选择已审核并且停用状态的促销进行启用');
            } else {
                const _params = {
                    code: row.code,
                    enableFlag: true,
                };
                UrlUtils.PatchRemote(this, '/promotion/enableOrDisable', _params, null, () => {
                    this.$confirm('操作成功');
                    this.handleQuery();
                });
            }
        },
        clearSearch() {
            this.form = {
                orderNumber: '', //订单号
                search: '', //商品名称
                deptGroupCode: '', //机构组code
                deptCode: '', //机构code
                receiverSearch: '', //收货人姓名和电话
                stockStartTime: '',
                stockEndTime: '',
                page: 1,
                limit: Util.Limit,
            };
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.promotion.plan.create', ['促销管理', '促销方案', '新建促销方案']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.mall.manage.order.open', ['电商管理', '电商销售出库详情', '电商销售出库详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus != 0) {
                this.$message.error('非待审核单据不能编辑');
            } else {
                Util.nameJump(this, 'menu.promotion.plan.edit', ['促销管理', '促销方案', '促销方案编辑'], {
                    form: row,
                    code: row.code,
                });
            }
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            if (!codes.length) {
                this.$confirm('没有勾选商品,将导出机构组或机构的全部商品数据,是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        UrlUtils.Export(
                            this,
                            '电商销售出库',
                            '/fromMultishop/onlineShoppingDeliveryOrder/exportData',
                            this.form,
                            codes
                        );
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消导出',
                        });
                    });
            } else {
                UrlUtils.Export(
                    this,
                    '电商销售出库',
                    '/fromMultishop/onlineShoppingDeliveryOrder/exportData',
                    this.form,
                    codes
                );
            }
        },
    },
    filters: {
        ruleType(type) {
            switch (type) {
                case 1: {
                    return '单品折扣';
                }
                case 2: {
                    return '单品偶数折扣';
                }
                case 3: {
                    return '单品特价';
                }
            }
        },
    },
};
</script>
<style scoped>
.boxwrap {
    display: flex;
}
.boxitem {
    flex: 1;
}
.boxitemflex2 {
    flex: 2;
}

.tabTitle {
    margin-bottom: 10px;
    overflow: hidden;
    font-size: 14px;
}
.tabItem {
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ebeef5;
    float: left;
    border-radius: 5px;
}
.actitem {
    border: 1px solid #19c989 !important;
    color: #19c989;
}
</style>
